<div class='NavBar' id="NavBar">
    <div class='NavBar-TopWrapper'>
        <router-link class="NavBar-LogoWrapper" to="/">
            <img class='NavBar-LogoLarge' src="../../../assets/images/MVZ-Logo-Horizontal.svg"/>
            <img class='NavBar-LogoSmall' src="../../../assets/images/MVZ-Logo-Horizontal.svg"/>
        </router-link>

        <span class="spacer"/>

        <div class='NavBar-LinkWrapper'>
            <router-link to="/" @click='() => isNavExtended = false'>
                <MvzButton :bText="$t('nav.home')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/team" @click='() => isNavExtended = false'>
                <MvzButton :bText="$t('nav.team')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <div class="NavBar-DropdownWrapper">
                <router-link to="/leistungen" @click='() => isNavExtended = false'>
                    <MvzButton :bText="$t('nav.treatments.title')" bVariant="nav" bTabIndex="-1"/>
                </router-link>
                <div class="NavBar-DropdownMenu">
                    <div class="NavBar-DropdownMenuContent">
                        <router-link to="/orthopaedie" @click='() => isNavExtended = false'>
                            <MvzButton :bText="$t('nav.treatments.orthopedics')" bVariant="nav" bTabIndex="-1"/>
                        </router-link>
                        <router-link to="/chirurgie" @click='() => isNavExtended = false'>
                            <MvzButton :bText="$t('nav.treatments.surgery')" bVariant="nav" bTabIndex="-1"/>
                        </router-link>
                        <router-link to="/neurochirurgie" @click='() => isNavExtended = false'>
                            <MvzButton :bText="$t('nav.treatments.neuroSurgery')" bVariant="nav" bTabIndex="-1"/>
                        </router-link>
                        <router-link to="/leistungen" @click='() => isNavExtended = false'>
                            <MvzButton :bText="$t('nav.treatments.services')" bVariant="nav" bTabIndex="-1"/>
                        </router-link>
                    </div>
                </div>
            </div>
            <!--                        <router-link to="/mission-statement" @click='() => isNavExtended = false'>
                                        <MvzButton :bText="$t('nav.mission')" bVariant="nav" bTabIndex="-1"/>
                                    </router-link>-->
            <router-link to="/stellenangebote" @click='() => isNavExtended = false'>
                <MvzButton :bText="$t('nav.jobs')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/kontakt" @click='() => isNavExtended = false'>
                <MvzButton :bText="$t('general.contact')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <div>
                <a href="https://webtermin.medatixx.de/#/303e0a90-ea8a-4d05-bcf7-bd6c5b5368e6" target="_blank">
                    <MvzButton
                            :bText="$t('general.arrangeAppointment')"
                            bIconAfter="calendar"
                            bTabIndex="-1"
                            bID="Home-HeroAppointments"
                            class="terminbuchung-trigger"
                            bVariant="primary"/>
                </a>
            </div>
            <div>
                <!--
                                <MvzButton :b-text="this.$i18n.locale.toUpperCase()" b-variant="transparent" @click="switchLanguage"/>
                -->
            </div>
        </div>
        <div class="NavBar-toggle">
            <MvzButton b-icon-before="menu" b-variant="nav" @click="toggleNavBar(!isNavExtended)" b-tab-index="-1"/>
        </div>
    </div>

    <div :class="isNavExtended ? 'NavBar-ExtendedWrapper extended' : 'NavBar-ExtendedWrapper'">
        <div :class="isShowTreatment ? 'NavBar-mobileNavContent showTreatments' : 'NavBar-mobileNavContent'">
            <router-link to="/" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.home')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/team" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.team')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <MvzButton :bText="$t('nav.treatments.title')" bVariant="nav" bTabIndex="-1" @click='() => isShowTreatment = true'/>
            <!--                        <router-link to="/mission-statement" @click='toggleNavBar(false)'>
                                        <MvzButton :bText="$t('nav.mission')" bVariant="nav" bTabIndex="-1"/>
                                    </router-link>-->
            <router-link to="/stellenangebote" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.jobs')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/kontakt" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('general.contact')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <MvzButton
                    :bText="$t('general.arrangeAppointment')"
                    bIconAfter="calendar"
                    bTabIndex="-1"
                    bID="Home-HeroAppointments"
                    class="terminbuchung-trigger"
                    bVariant="primary"
                    @click="openAppointmentPicker"
                    data-configid="303e0a90-ea8a-4d05-bcf7-bd6c5b5368e6"
                    style="margin-top: 3rem"
            />
        </div>
        <div :class="isShowTreatment ? 'NavBar-mobileNavTreatments showTreatments' : 'NavBar-mobileNavTreatments'">
            <MvzButton :bText="$t('general.back')" bVariant="nav" bTabIndex="-1" @click='() => isShowTreatment = false'/>
            <router-link to="/orthopaedie" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.treatments.orthopedics')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/chirurgie" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.treatments.surgery')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/neurochirurgie" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.treatments.neuroSurgery')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
            <router-link to="/leistungen" @click='toggleNavBar(false)'>
                <MvzButton :bText="$t('nav.treatments.services')" bVariant="nav" bTabIndex="-1"/>
            </router-link>
        </div>
        <!--
                <MvzButton :b-text="this.$i18n.locale.toUpperCase()" b-variant="transparent" @click="switchLanguage" class="NavBar-LanguageSwitcher"/>
        -->
        <div style="height: 4rem; display: block"/>
    </div>
</div>