import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from "@/views/HomeView/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/TeamView/TeamView.vue')
  },
  {
    path: '/leistungen',
    name: 'leistungen',
    component: () => import('../views/TreatmentsView/TreatmentsView.vue')
  },
  {
    path: '/orthopaedie',
    name: 'Orthopaedie',
    component: () => import('../views/SpecialityViews/OrthopedicsView.vue')
  },
  {
    path: '/chirurgie',
    name: 'Chirurgie',
    component: () => import('../views/SpecialityViews/SurgeryView.vue')
  },
  {
    path: '/neurochirurgie',
    name: 'Neurochirurgie',
    component: () => import('../views/SpecialityViews/NeuroSurgeryView.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import('../views/ContactView/ContactView.vue')
  },
  {
    path: '/stellenangebote',
    name: 'Stellenangebote',
    component: () => import('../views/JobOfferView/JobOfferView.vue')
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('../views/privacyPolicy.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/impressum.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0}
  },
  routes
})

export default router
