<template>
    <nav>
        <MvzNavBar/>
    </nav>
    <main id="page-content">
        <router-view/>
    </main>
    <mvz-footer/>
</template>

<script lang="ts">
import MvzNavBar from "@/components/layout/NavBar/MvzNavBar.vue";
import MvzFooter from "@/components/layout/Footer/MvzFooter.vue";

export default {
    components: {MvzFooter, MvzNavBar},
}
</script>

<style lang="scss">
@import "@/assets/style/fonts.scss";
@import "@/assets/style/appointmentPicker.scss";

a {
    text-decoration: none;
    text-decoration-line: none;
}

body {
    margin: 0;
    overflow: hidden auto;
}

#page-content {
    padding-top: 4.5rem;
}
html {
    scroll-behavior: smooth;
}

</style>