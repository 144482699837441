<template lang='html' src='./NavBarTemplate.html'/>
<style lang="scss" src='./NavBarStyle.scss'/>

<script lang="ts">
import { Options, Vue} from "vue-class-component";
import MvzButton from "@/components/inputs/Button/MvzButton.vue";
import { useI18n } from "vue-i18n";

@Options({
  components: {
    MvzButton,
  },
  props: ['navBarExtended']
})

export default class MvzNavBar extends Vue {
  isNavExtended = false
  isShowTreatment = false

  toggleNavBar = (state: boolean) => {
    this.isNavExtended = state
    setTimeout(() => this.isShowTreatment = false, 300)
  }

  switchLanguage(locale: string) {
    if(this.$i18n.locale == 'de') {
      this.$i18n.locale = 'en';
    } else this.$i18n.locale = 'de'
  }
}
</script>