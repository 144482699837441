import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";

import {createI18n} from 'vue-i18n'
import german from './i18n/de.json'
import english from './i18n/en.json'

const i18n = createI18n({
  legacy: false,
  locale: 'de',
  fallbackLocale: 'de',
  globalInjection: true,
  messages: {
    de: german,
    en: english,
  }
});

createApp(App)
  .use(i18n)
  .use(router)
  .mount('#app')