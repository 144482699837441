import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47eb3e8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "tabindex", "type"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  style: {"pointer-events":"none"}
}
const _hoisted_4 = ["src"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("button", {
    id: _ctx.bID,
    class: _normalizeClass(_ctx.bVariant),
    tabindex: _ctx.bTabIndex,
    type: _ctx.bType,
    "onClick!": _cache[0] || (_cache[0] = (...args) => (_ctx.bClick && _ctx.bClick(...args)))
  }, [
    (_ctx.bIconBefore)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require('@/assets/icons/' + _ctx.bIconBefore + '.svg')
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.bText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.bText), 1))
      : _createCommentVNode("", true),
    (_ctx.bIconAfter)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: require('@/assets/icons/' + _ctx.bIconAfter + '.svg')
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}