<template lang="html" src='./ButtonTemplate.html'/>
<style lang='scss' src='./ButtonStyle.scss' scoped/>

<script lang='ts'>

export default {
  props: {
    bClick: String,
    bID: String,
    bTabIndex: String,
    bVariant: String, // primary, neutral-dark, neutral-light, transparent, nav
    bSize: String, // small, medium, large
    bText: String,
    bIconBefore: String,
    bIconAfter: String,
    bType: String,
  }
}
</script>