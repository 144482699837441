<template lang='html' src='./HomeTemplate.html'/>
<style lang='scss' src='./HomeStyle.scss' scoped/>

<script lang='ts'>
import MvzButton from "@/components/inputs/Button/MvzButton.vue";
import MvzTreatmentTile from "@/components/layout/TreatmentTile/MvzTreatmentTile.vue";
import MvzNewsEntry from "@/components/layout/NewsEntry/MvzNewsEntry.vue";
import news from "@/content/news.json"

export default {
  components: {
    MvzButton,
    MvzTreatmentTile,
    MvzNewsEntry
  },
  data() {
    return {news: news}
  },
  methods: {
    copyToClipboard(data: string) {
      try {
        navigator.clipboard.writeText(data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>