<section id='home-hero' class='Home-Hero'>
    <div class='Wrapper'>
        <img class="DoctorsSmall" src='../../assets/images/drTeam.jpg'/>
        <div class='HeaderWrapper'>
            <h2 class="Welcome">{{ $t('home.hero.welcome') }}</h2>
            <img class='Logo' src='@/assets/images/MVZ-Logo-Horizontal.svg'/>
            <p class="Slogan large">{{ $t('home.hero.description') }}</p>
            <div class='HeaderQuickActions'>
                <router-link to="/kontakt">
                    <MvzButton
                            :bText="$t('general.contact')"
                            bVariant="transparent"
                            bID="Home-HeroContact"
                    />
                </router-link>
                <a href="https://webtermin.medatixx.de/#/303e0a90-ea8a-4d05-bcf7-bd6c5b5368e6" target="_blank">
                <MvzButton
                        :bText="$t('general.arrangeAppointment')"
                        bIconAfter="calendar"
                        bTabIndex="-1"
                        bID="Home-HeroAppointments"
                        class="terminbuchung-trigger"
                        bVariant="primary"/>
                </a>
            </div>
        </div>
        <img class="DoctorsLarge" src='../../assets/images/drTeam.jpg'/>
        <p class="Introduction large">
            {{ $t('home.hero.mission') }}
        </p>
    </div>
</section>

<section id='home-news' class='Home-News'>
    <div class="Wrapper">
        <h2 class="Title">Aktuelle Meldungen</h2>
        <MvzNewsEntry v-for="entry in news"
                      :ne-title="this.$t(entry.title)"
                      :ne-body="this.$t(entry.body)"
        />
    </div>
</section>

<section id='home-treatments' class='Home-Treatments'>
    <h2>Behandlungen</h2>
    <div class='Wrapper'>
        <MvzTreatmentTile
                :ttTitle="$t('home.specialization.orthopedics.title')"
                ttIcon="orthopedics"
                ttBody=""
                ttLink="/orthopaedie"
        />
        <MvzTreatmentTile
                :ttTitle="$t('home.specialization.surgery.title')"
                ttIcon="chirurgie"
                ttBody=""
                ttLink="/chirurgie"
        />
        <MvzTreatmentTile
                :ttTitle="$t('home.specialization.neurosurgery.title')"
                ttIcon="neurochirurgie"
                ttBody=""
                ttLink="/neurochirurgie"
        />
        <MvzTreatmentTile
                :ttTitle="$t('home.specialization.services.title')"
                ttIcon="leistungen"
                ttBody=""
                ttLink="/leistungen"
        />
    </div>
</section>

<section id="home-contact" class="Home-Contact">
    <div class="Wrapper">
        <h2 class="Title">{{ $t('home.contact.title') }}</h2>
        <div class="contactRow">
            <div class="contactColumn">
                <div class="contactWrapper mobileWhite">
                    <div class="contactTitleWrapper">
                        <img src="@/assets/icons/mail.svg"/>
                        <h4>{{ $t('home.contact.tiles.mail.title') }}</h4>
                        <a href="mailto:service@mvz-atrium.de">
                            <MvzButton b-variant="tile" :b-text="$t('home.contact.tiles.mail.cta')"/>
                        </a>
                    </div>
                    <p>service@mvz-atrium.de</p>
                </div>
                <div class="contactWrapper mobileWhite">
                    <div class="contactTitleWrapper">
                        <img src="@/assets/icons/phone.svg"/>
                        <h4>{{ $t('information.phone.title') }}</h4>
                        <a href="tel:08024475420">
                            <MvzButton b-variant="tile" :b-text="$t('home.contact.tiles.phone.cta')"/>
                        </a>
                    </div>
                    <p>{{ $t('information.phone.phone') }}</p>
                </div>
                <div class="contactWrapper mobileWhite">
                    <div class="contactTitleWrapper">
                        <img src="@/assets/icons/fax.svg"/>
                        <h4>{{ $t('information.fax.title') }}</h4>
                        <!--                        <MvzButton b-variant="tile" :b-text="$t('home.contact.tiles.fax.cta')" :b-click="copyToClipboard('080244754260')"/>-->
                    </div>
                    <p>{{ $t('information.fax.fax') }}</p>
                </div>
            </div>
            <div class="contactColumn">
                <div class="contactWrapper">
                    <div class="contactTitleWrapper">
                        <img src="@/assets/icons/clock.svg"/>
                        <h4>{{ $t('home.contact.tiles.opening.title') }}</h4>
                        <a href="https://webtermin.medatixx.de/#/303e0a90-ea8a-4d05-bcf7-bd6c5b5368e6" target="_blank">
                        <MvzButton b-variant="tile" :b-text="$t('home.contact.tiles.opening.cta')"
                                   class="terminbuchung-trigger"/>
                        </a>
                    </div>
                    <div class="contactTitleWrapper">
                        <p style="flex-grow: 1">{{ $t('information.openingHours.entry1.days') }}</p>
                        <p>{{ $t('information.openingHours.entry1.hours') }}</p>
                    </div>
                    <div class="contactTitleWrapper">
                        <p style="flex-grow: 1">{{ $t('information.openingHours.entry2.days') }}</p>
                        <p>{{ $t('information.openingHours.entry2.hours') }}</p>
                    </div>
                </div>
                <div class="contactWrapper">
                    <div class="contactTitleWrapper">
                        <img src="@/assets/icons/map-pin.svg"/>
                        <h4>{{ $t('home.contact.tiles.address.title') }}</h4>
                        <a href="https://maps.app.goo.gl/vAdNDdefPqRyhFk8A" target=”_blank”>
                            <MvzButton b-variant="tile" :b-text="$t('home.contact.tiles.address.cta')"/>
                        </a>
                    </div>
                    <p>{{ $t('home.contact.tiles.address.content.1') }}</p>
                    <p>{{ $t('home.contact.tiles.address.content.2') }}</p>
                    <p>{{ $t('home.contact.tiles.address.content.3') }}</p>
                </div>
            </div>
        </div>
    </div>
</section>
