<footer class="Footer">
    <div class="generalWrapper">
        <p>MVZ Atrium Ärzte PartG mbB</p>
        <p class="small">Münchnerstraße 56 a, 83607 Holzkirchen</p>
        <div class="documentLinkWrapper">
            <RouterLink class="small" to="/impressum">
                <p class="small">Impressum</p>
            </RouterLink>
            <p class="small">•</p>
            <RouterLink class="small" to="/datenschutz">
                <p class="small">Datenschutz</p>
            </RouterLink>
        </div>
    </div>
    <div class="infoWrapper">
        <div class="infoList">
            <h5>Inhalte</h5>
            <router-link to="/team">
                <p class="small">Team</p>
            </router-link>
            <router-link to="/orthopaedie">
                <p class="small">Orthopädie</p>
            </router-link>
            <router-link to="/chirurgie">
                <p class="small">Chirurgie</p>
            </router-link>
            <router-link to="/neurochirurgie">
                <p class="small">Neurochirurgie</p>
            </router-link>
            <router-link to="/leistungen">
                <p class="small">Leistungen</p>
            </router-link>
            <router-link to="/stellenangebote">
                <p class="small">Stellenangebote</p>
            </router-link>
            <router-link to="/Kontakt">
                <p class="small">Kontakt</p>
            </router-link>
        </div>
        <div class="infoList">
            <h5>Kontakt</h5>
            <a href="tel:08024475420">
                <img class="Icon" src="../../../assets/icons/phone.svg"/>
                <p class="small">+49 (0) 8024 475420</p>
            </a>
            <router-link to="/">
                <img class="Icon" src="../../../assets/icons/fax.svg"/>
                <p class="small">+49 (0) 8024 4754260</p>
            </router-link>
            <a href="mailto:service@mvz-atrium.de">
                <img class="Icon" src="../../../assets/icons/mail.svg"/>
                <p class="small">service@mvz-atrium.de</p>
            </a>
            <router-link to="/">
                <img class="Icon" src="../../../assets/icons/map-pin.svg"/>
                <p class="small">www.mvz-atrium.de</p>
            </router-link>
        </div>
    </div>
</footer>