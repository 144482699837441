<div class="NewsEntry">
    <button class="EntryTitleWrapper" @click="toggleDropdown">
        <h3 class="EntryTitle">{{neTitle}}</h3>
        <img class="FoldedIndicator" src="../../../assets/icons/chevron-down.svg"/>
    </button>
    <div class="ContentWrapper">
        <div class="EntryInformation">
            <p>{{neBody}}</p>
        </div>
    </div>
</div>