<template lang="html" src="./FooterTemplate.html"/>
<style lang="scss" src="./FooterStyle.scss"/>

<script lang="ts">

import {Vue} from "vue-class-component";

export default class MvzFooter extends Vue {

}
</script>