<template lang='html' src='./NewsEntryTemplate.html'/>
<style lang='scss' src='./NewsEntryStyle.scss'/>

<script lang='ts'>
import {Options, Vue} from "vue-class-component";

@Options({
  props: {
    neTitle: String,
    neBody: String,
  },
})

export default class MvzNewsEntry extends Vue {

  toggleDropdown(event: MouseEvent) {
    console.log(event.target)
    if(!event.target) return
    // @ts-ignore
    let infoContainer = event.target.parentNode.getElementsByClassName('ContentWrapper')[0]
    if(infoContainer.classList.contains("open")) {
      infoContainer.classList.remove("open")
    } else {
      infoContainer.classList.add("open")
    }
  }
}
</script>