<template lang='html' src='./TreatmentTileTemplate.html'/>
<style lang='scss' src='./TreatmentTileStyle.scss'/>

<script lang='ts'>

export default {
  props: {
    ttTitle: String,
    ttIcon: String,
    ttBody: String,
    ttLink: String,
  },
}

</script>